import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Bimetal = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>
						隔測式温度計（注文製作品：防水型）
						<br class="sp" />
						図面（PDFファイル）
					</h2>
					<div className="content-container">
						<ul className="documents-ul flex wrap">
						<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lbw-100s.pdf" target={"_blank"} rel="noreferrer">
									防水・壁掛型 LBW-100S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/ldw-100s.pdf" target={"_blank"} rel="noreferrer">
									防水・埋込型 LDW-100S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/lasw-100s.pdf" target={"_blank"} rel="noreferrer">
									防水・直結式S型 LASW-100S
								</a>
							</li>
							<li className="documents-li">
								<a href="https://static.sksato.co.jp/corporate/documents/remote-measurement-type-order/latw-100s.pdf" target={"_blank"} rel="noreferrer">
									防水・直結式T型 LATW-100S
								</a>
							</li>
						</ul>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Bimetal;
